import React from 'react'
import PropTypes from 'prop-types'

import ContactForm from './ContactForm'

import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      />
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
            Based in North Andover, MA, Oakpoint Digital is a full service web
            development agency. We help businesses improve their digital
            presence in order to make great impressions and increase sales.
          </p>
          <p>
            Whether your business is a fortune 500 corporation or a budding
            startup, you can’t afford to not have a digital presence in the
            current market. With a great website you will gain an edge over your
            competition and create a more memorable brand. This can lead to
            better customer retention, higher conversion rates, and as a result
            a more profitable business.
          </p>
          <p>
            What are you waiting for? Take the first step in leveling up your
            business by contacting us today to set up a free consultation to
            discuss your project.
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Work</h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <p>
            We develop modern web solutions that strengthen brands and produce
            results. We focus on three core areas of expertise.
          </p>
          <p>
            <strong>Design: </strong>
            Creative solutions uniquely tailored to your brand and customer
            base. Modern designs that are both aesthetically pleasing and easily
            usable, to help create exceptional user experiences.
          </p>
          <p>
            <strong>Development: </strong>
            Professionally developed websites that have fast loading times, are
            easily maintainable, and optimized for all modern browsers.
            Customized interactive solutions to meet whatever unique goals your
            company is trying to achieve.
          </p>
          <p>
            <strong>Search Engine Optimization: </strong>
            Best practices used at all stages of development to quickly boost
            your search engine rankings. Easily accessible pages and proven
            marketing strategies that will draw traffic effortlessly from the
            modern web.
          </p>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <p>
            Oakpoint Digital, LLC is based in North Andover Massachusetts and
            focuses on serving customers in the Greater Boston Area.
          </p>
          <p>
            The owner and primary web developer is Lucas Lombardo. Lucas had his
            first exposure in web development when he was 15 working as an
            impromptu webmaster for the New England Chapter of National Corvette
            Restorers Society. He graduated from Boston University in 2016 and
            currently resides in North Andover with his cat Cocoa.
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <p>
            Reach out below to set up a free consultation, hear more about what
            we have to offer, or just to say hi. We'll get back to you within
            one business day and set up a time to chat.
          </p>
          <ContactForm action="/thanks/" />
          <div className="other-contact">
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path fill="none" d="M0 0h24v24H0V0z" />
                <path d="M19.23 15.26l-2.54-.29c-.61-.07-1.21.14-1.64.57l-1.84 1.84c-2.83-1.44-5.15-3.75-6.59-6.59l1.85-1.85c.43-.43.64-1.03.57-1.64l-.29-2.52c-.12-1.01-.97-1.77-1.99-1.77H5.03c-1.13 0-2.07.94-2 2.07.53 8.54 7.36 15.36 15.89 15.89 1.13.07 2.07-.87 2.07-2v-1.73c.01-1.01-.75-1.86-1.76-1.98z" />
              </svg>
              <span> </span>
              <a href="tel:1-978-729-2187">(978) 729-2187</a>
            </p>
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path fill="none" d="M0 0h24v24H0V0z" />
                <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-.4 4.25l-7.07 4.42c-.32.2-.74.2-1.06 0L4.4 8.25c-.25-.16-.4-.43-.4-.72 0-.67.73-1.07 1.3-.72L12 11l6.7-4.19c.57-.35 1.3.05 1.3.72 0 .29-.15.56-.4.72z" />
              </svg>{' '}
              <span> </span>
              <a href="mailto:lucas.lombardo@oakpoint.io">
                lucas.lombardo@oakpoint.io
              </a>
            </p>
          </div>
          {/* <ul className="icons">
            <li>
              <a href="#" className="icon fa-twitter">
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="#" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="#" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a href="#" className="icon fa-github">
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul> */}
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
