import React from 'react'

const ContactForm = ({ action, name }) => (
  <form
    key="contact-form"
    name="oakpoint-contact"
    method="POST"
    action={action}
    data-netlify-honeypot="full-name"
    data-netlify="true"
  >
    <div style={{ display: 'none' }}>
      <label htmlFor="full-name">
        Don’t fill out this field if you’re a human.
      </label>
      <input type="text" id="full-name" name="full-name" />
    </div>
    <div className="field half first">
      <label htmlFor="fname">Name</label>
      <input type="text" id="fname" name="name" required />
    </div>
    <div className="field half">
      <label htmlFor="email">Email</label>
      <input type="email" id="email" name="email" required />
    </div>
    <div className="field">
      <label htmlFor="message">Message</label>
      <textarea id="message" name="message" rows="4" required />
    </div>
    <ul className="actions">
      <li>
        <button type="submit">
          <span>Send Message</span>
        </button>
      </li>
    </ul>
    <input type="hidden" name="form-name" value="oakpoint-contact" />
  </form>
)

export default ContactForm
